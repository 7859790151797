.teacher-TH-cell1 {
    width: 10% !important;
    background-color: white !important;
    color: black !important;
    padding: 12px !important;
    font-weight: 600 !important;
    font-size: 16px !important;
}

.teacher-TH-cell2 {
    width: 20% !important;
    background-color: white !important;
    color: black !important;
    padding: 12px !important;
    font-weight: 600 !important;
    font-size: 16px !important;
}

.teacher-TH-cell3 {
    width: 50% !important;
    background-color: white !important;
    color: black !important;
    padding: 12px !important;
    font-weight: 600 !important;
    font-size: 16px !important;
}

.teacherview-back-btn-parent-div{
    display: flex !important;
    align-items: center !important;
    justify-content: space-between;
    margin: 0px 0px 36px 0px;
}