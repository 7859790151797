.grade-new-essay-btn{
    display: flex !important;
    background-color: black !important;
    text-transform: capitalize !important;
    color: white !important;
    width: 90% !important;
    border-radius: 50px !important;
    height: 46px !important;
}

.page-name{
    margin: 0px !important;
    display: flex !important;
    align-items: center !important;
    font-size: 18px !important;
    height: 46px !important;
}

.search-field{
    border-radius: 50px !important;
    border: none !important;
    width: 100%;
    background-color: #e4e3e3;
}

.search-div input{
    padding: 11.5px 14px !important;
}

.search-div fieldset{
    border-width: 0px !important;
}

.essay-TH-cell1{
    background-color: white !important;
    color: black !important;
    padding: 12px !important;
    width: 11% !important;
    font-weight: 600 !important;
    font-size: 16px !important;
}

.essay-TH-cell2{
    background-color: white !important;
    color: black !important;
    padding: 12px !important;
    width: 13% !important;
    font-weight: 600 !important;
    font-size: 16px !important;
}

.essay-TH-cell3{
    background-color: white !important;
    color: black !important;
    padding: 12px !important;
    width: 13% !important;
    font-weight: 600 !important;
    font-size: 16px !important;
}

.essay-TH-cell4{
    background-color: white !important;
    color: black !important;
    padding: 12px !important;
    width: 13% !important;
    font-weight: 600 !important;
    font-size: 16px !important;
}

.essay-TH-cell5{
    background-color: white !important;
    color: black !important;
    padding: 12px !important;
    width: 8% !important;
    font-weight: 600 !important;
    font-size: 16px !important;
}

.essay-TH-cell6{
    background-color: white !important;
    color: black !important;
    padding: 12px !important;
    width: 21% !important;
    font-weight: 600 !important;
    font-size: 16px !important;
}

.essay-TH-cell7{
    background-color: white !important;
    color: black !important;
    padding: 12px !important;
    width: 8% !important;
    font-weight: 600 !important;
    font-size: 16px !important;
}

.search-parent-div{
    display: flex !important;
    justify-content: end !important;
}

.search-div{
    width: 60%;
}

.grade-new-essay-btn-div{
    width: 40%;
    display: flex;
    justify-content: end;
}

.loder-div-styles{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20%;
}