.login-form-parent-div{
    width:100%;
    background-color: white;
}

.login-text-fields{
    width: 100%;
}

.field-warps{
    margin: 20px 0px;
}

.login-field-lables{
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16.8px;
    margin-bottom: 8px;
}
.form-wraps{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
}

.login-button{
    width: 100% !important;
    background: black !important;
    color: white !important;
    padding: 10px 0px !important;
    text-transform: capitalize !important;
    border-radius: 50px !important;
}

.teacher-login-lable{
    font-size: 18px;
    font-weight: 600;
    font-style: normal;
    line-height: 21.6px;
}

.error{
    font-size: 12px;
    color: red;
}

.invalid-data{
    color: red;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
}

.page-background{
    height: "100vh";
    background-color: #6374FA;;
   
}
.carousel-over-all-div-warps{
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    height: 100vh;
}
.login-left-inner{
    max-width: 448px;
    width: 100%;
}

.login-slide-img{
    text-align: center;
    justify-content: center;
    display: flex;
    margin-bottom: 48px;
}

.login-slide-text{
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    border-radius: 12px;
    background: rgba(255, 255, 255, 0.14);
}

.carousel-title{
    color: #FFFFFF;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 21.6px;
    margin: 0px 0px 8px 0px;
}
.carousel-content{
    color: #FFFFFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22.4px;
    margin-bottom: 0;
}

.login-button-next {
    position: absolute;
    right: -60px;
    bottom: 110px;
    background: rgba(#ffffff, 0.5);
    min-width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    border-radius: 50%;
}

.login-button-prev {
    position: absolute;
    left: -60px;
    bottom: 110px;
    min-width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    border-radius: 50%;
}


