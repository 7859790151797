.template-TH-cell1 {
    background-color: white !important;
    color: black !important;
    padding: 12px !important;
    width: 25% !important;
    font-weight: 600 !important;
    font-size: 16px !important;
}

.template-TH-cell2 {
    background-color: white !important;
    color: black !important;
    padding: 12px !important;
    width: 15% !important;
    font-weight: 600 !important;
    font-size: 16px !important;
}

.template-TH-cell3 {
    background-color: white !important;
    color: black !important;
    padding: 12px !important;
    width: 10% !important;
    font-weight: 600 !important;
    font-size: 16px !important;
}

.view-edit-template-TH-cell1 {
    background-color: white !important;
    color: black !important;
    padding: 12px !important;
    width: 27% !important;
    font-weight: 600 !important;
    font-size: 16px !important;
}

.view-edit-template-TH-cell2 {
    background-color: white !important;
    color: black !important;
    padding: 12px !important;
    width: 13% !important;
    font-weight: 600 !important;
    font-size: 16px !important;
}

.view-edit-template-TH-cell3 {
    background-color: white !important;
    color: black !important;
    padding: 12px !important;
    width: 17% !important;
    font-weight: 600 !important;
    font-size: 16px !important;
}

.view-edit-template-TH-cell4 {
    background-color: white !important;
    color: black !important;
    padding: 12px !important;
    width: 20% !important;
    font-weight: 600 !important;
    font-size: 16px !important;
}

.view-edit-template-TH-cell5 {
    background-color: white !important;
    color: black !important;
    padding: 12px !important;
    width: 8% !important;
    font-weight: 600 !important;
    font-size: 16px !important;
}

.view-edit-template-partent-div {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.view-edit-form-parent-div {
    margin: 30px 0px 0px 0px;
    width: 100%;
    background-color: #fff;
    padding: 10px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.view-edit-form-div {
    margin: 15px 0px;
    width: 100%;
    background-color: #fff;
    padding: 10px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.max-words-mark-parent-div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 0px 0px 14px 0px;
}

.view-edit-form-max-mark-word {
    width: 49%;
}


.view-edit-form-question {
    margin: 0px 0px 14px 0px;
}

.view-edit-btn-parent-div {
    display: flex;
    justify-content: center;
    margin: 20px 0px;
}

.view-edit-template-action-div {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.table-textfield {
    width: 100%;
}

.table-textfield .MuiInputBase-root {
    padding: 10px;
}

.add-row-btn-view-edit {
    display: flex !important;
    text-transform: capitalize !important;
    height: 46px !important;
    color: black !important;
    border: 1px solid black !important;
}

.add-row-btn-view-edit:hover {
    background-color: transparent !important;
}

.view-edit-save-btn {
    display: flex !important;
    background-color: black !important;
    text-transform: capitalize !important;
    color: white !important;
}

.icon-styles {
    cursor: pointer;
}

.view-edit-template-TB-cell1 {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 150px;
}

.template-action-div {
    display: flex;
    justify-content: space-around;
}

.is-handwriting-formcontrol-template {
    display: flex !important;
    flex-direction: row !important;
    margin: 20px 0px 0px 0px !important;
    align-items: center !important;
}

.add-row-btn-parent-div {
    display: flex;
    justify-content: end;
}