.admin-student-TH-cell1 {
    width: 10% !important;
    background-color: white !important;
    color: black !important;
    padding: 12px !important;
    font-weight: 600 !important;
    font-size: 16px !important;
}

.admin-student-TH-cell2 {
    width: 15% !important;
    background-color: white !important;
    color: black !important;
    padding: 12px !important;
    font-weight: 600 !important;
    font-size: 16px !important;
}

.admin-student-TH-cell3 {
    width: 25% !important;
    background-color: white !important;
    color: black !important;
    padding: 12px !important;
    font-weight: 600 !important;
    font-size: 16px !important;
}