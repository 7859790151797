.toolbar>div:nth-child(2) {
    width: 100%;
}

.icnBtn {
    background: #fff !important;
    padding-top: 5px;
}


.icn {
    margin: 0px 10px !important;
}

.list-content.MuiListItem-gutters {
    position: relative;
}

.sidebar-menu img {
    width: 6.5rem;
}

.header-main {
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
}

.appBar-content {
    box-shadow: none !important;
    border-bottom: 0px solid #E8E8E8;
    background-color: white !important;
    width: 5% !important;
    margin: 0px !important;
    left: 0 !important;
}

.icn:hover {
    background-color: white !important;
}



.sidebar-logo img {
    height: 5rem;
}

.sidebar-logo {
    margin-right: 25px;
    position: block;
    text-align: center !important;
    padding-bottom: 18px !important;
    font-weight: 700 !important;
    font-size: 28px !important;
    line-height: 44px;
    letter-spacing: 0.015em;
    color: #5D5E60;
}

.listItem-text {
    align-items: center;
    color: #6F6C99;
    font-weight: 500 !important;
    font-size: 15px !important;
    line-height: 17px;
}

.logo {
    display: block;
    margin-top: 25px !important;
    margin-bottom: 25px !important;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
}

.sub_menu {
    padding-left: 28px !important;
}


.title-logo {
    padding-left: 35px;
    padding-right: 30px;
}


.listItem-text>.MuiTypography-root {
    font-family: inherit !important;
    color: #6f6c99;
    font-size: 15px !important;
    font-weight: 400;
    letter-spacing: .5px;
    line-height: 17px;
}

.listItem-text>.MuiTypography-root:hover {
    background: none;
    color: #000000;
    line-height: 17px;
    font-size: 15px !important;
    Text-Decoration: None !important;
}

.listItem-text>.css-10hburv-MuiTypography-root {
    font-family: inherit !important;
    font-size: 1rem;
}

.listItem-text-active {

    background: none;
    color: #000000;
    line-height: 17px !important;
    font-size: 15px !important;
    Text-Decoration: None !important;
}

.listItem-text-active>.MuiTypography-root {
    font-family: inherit !important;
    background: none;
    color: #000000;
    line-height: 17px !important;
    font-size: 16px !important;
    Text-Decoration: None !important;
}

.sideBar-main {
    top: 0px;
    left: 0px;
    height: 100vh !important;
    width: 210px !important;
    box-shadow: rgba(0, 0, 0, 0.06) 0px 5px 5px -3px, rgba(0, 0, 0, 0.043) 0px 8px 10px 1px, rgba(0, 0, 0, 0.035) 0px 3px 14px 2px;
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
    z-index: 111;
    overflow: hidden;
    color: rgb(255, 255, 255);
    transition: all 250ms ease-in-out 0s;
}

.sidebar-content {
    border-style: hidden;
    margin-top: 6px;
}

.MuiListItem-button:hover {
    background-color: #6f6c99 !important;
}

.MuiTouchRipple-rippleVisible {
    display: none;
}

.sidebar-list-content img {
    height: 20px;
    width: 20px;
}

.listItem-icon {
    min-width: 56px;
    padding-left: 6px;
}

.link-list {
    text-decoration: none !important;

}

.outlet {
    min-height: calc(100vh - 70px);
}

.logout-ui-div {
    position: absolute !important;
    bottom: 0 !important;
    background-color: #fff !important;
    width: 100% !important;
}