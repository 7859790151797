.myStudent-TH-cell1 {
    width: 8% !important;
    background-color: white !important;
    color: black !important;
    padding: 12px !important;
    font-weight: 600 !important;
    font-size: 16px !important;
}

.myStudent-TH-cell2 {
    width: 13% !important;
    background-color: white !important;
    color: black !important;
    padding: 12px !important;
    font-weight: 600 !important;
    font-size: 16px !important;
}

.myStudent-TH-cell3 {
    width: 18% !important;
    background-color: white !important;
    color: black !important;
    padding: 12px !important;
    font-weight: 600 !important;
    font-size: 16px !important;
}

.myStudent-TH-cell4 {
    width: 10% !important;
    background-color: white !important;
    color: black !important;
    padding: 12px !important;
    font-weight: 600 !important;
    font-size: 16px !important;
}

.myStudent-TH-cell5 {
    width: 15% !important;
    background-color: white !important;
    color: black !important;
    padding: 12px !important;
    font-weight: 600 !important;
    font-size: 16px !important;
}


.add-student-pratent-div {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 3% 0%;
}

.add-student-back-btn-div {
    width: 8%;
}

.view-profile-TH-cell1 {
    width: 10% !important;
    background-color: white !important;
    color: black !important;
    padding: 12px !important;
    font-weight: 600 !important;
    font-size: 16px !important;
}

.view-profile-TH-cell2 {
    width: 20% !important;
    background-color: white !important;
    color: black !important;
    padding: 12px !important;
    font-weight: 600 !important;
    font-size: 16px !important;
}

.view-profile-TH-cell3 {
    width: 60% !important;
    background-color: white !important;
    color: black !important;
    padding: 12px !important;
    font-weight: 600 !important;
    font-size: 16px !important;
}

.delete-btn {
    display: flex !important;
    background-color: transparent !important;
    text-transform: capitalize !important;
    color: red !important;
    border: 1px solid red !important;
    width: 100% !important;
    height: 46px !important;
}

.view-profile-tag {
    text-decoration: underline !important;
    color: #0a66f0f2 !important;
    cursor: pointer !important;
}

.add-new-student-btn {
    display: flex !important;
    background-color: black !important;
    text-transform: capitalize !important;
    color: white !important;
    width: 100% !important;
    border-radius: 50px !important;
    height: 46px !important;
}

.studentview-back-btn-parent-div {
    display: flex !important;
    align-items: center !important;
}

.studnetView-back-btn-div {
    margin: 0px 20px 0px 0px !important;
}

.student-back-btn {
    display: flex !important;
    background-color: transparent !important;
    text-transform: capitalize !important;
    color: #1976d2 !important;
    border: 1px solid rgba(25, 118, 210, 0.5) !important;
    width: 100% !important;
    height: 46px !important
}

.studnet-search-div input{
    padding: 11.5px 14px !important;
}

.studnet-search-div fieldset{
    border-width: 0px !important;
}