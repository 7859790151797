.setting-save-btn{
    display: flex;
    justify-content: center;
    margin: 20px 0px;
}

.setting-page-loader-div{
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #ffffffb5;
    z-index: 3;
}