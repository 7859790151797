.essay-feedback-parent-warps {
    padding: 20px;
    background: #e9e9e9;
    width: 100%;
}

.feedback-header-section {
    background-color: #fff;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border: 1px solid #f7f7f7;
    padding: 10px;
}

.feedback-header-section>img {
    cursor: pointer;
}

.commonHeaderBoldStyles {
    font-size: 20px;
    margin: 0px;
    padding: 0px 0px 0px 10px;
}

.headerMainTitle {
    font-size: 24px;
    font-weight: 500;
    margin: 0px;
}

.header-first-section {
    width: 50%;
    display: flex;
    align-items: center;
}

.navSection {
    align-items: center;
    justify-content: end;
    width: 50%;
    display: flex;
}

.question-parent-div-warp {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border: 1px solid white;
    border-radius: 12px;
    padding: 10px;
    margin: 0px 0px 24px;
}

.question-lables {
    font-size: 24px;
    margin: 0px 0px 16px 0px;
}

.question-values {
    font-size: 14px;
    margin: 0px;
}

.question-tabs {
    background-color: white;
    margin: 0px 0px 24px;
    color: black;
    width: 100%;
}

.right-side-question-paper {
    width: 100%;
    background-color: white;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    border-radius: 10px;
}



.page-content-main-wrapper {
    display: flex;
    position: relative;
    background-color: #e5e5e5;
    border-radius: 12px;
    margin-bottom: 16px;
}

.page-content-image-wrapper {
    position: relative;
    width: max-content;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 20px;
    margin-right: 8px;
}

.page-content-feedback-section-wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 6px;
    position: relative;
    width: 70%;
    margin-left: 8px;
    background-color: white;
    border-radius: 20px;
}

.image-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
    z-index: 1;
}

.high-priority-banner {
    border: 2px solid #FFB6C1;
}

.medium-priority-banner {
    border: 2px solid #FFD700;
}

.low-priority-banner {
    border: 2px solid #98FB98;
}


.feedback-card {
    border: 1px solid #ccc;
    margin: 12px;
    padding: 10px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    position: relative;
}

.feedbackpriorityinnerCard {
    position: relative;
    width: 70%;
    border: 1px solid;
    padding: 10px;
    border-radius: 24px;
    display: flex;
    justify-content: start;
}

.feedbackEditIcon {
    width: 30px;
    cursor: pointer;
}

.feedbackCardInnerWrapper {
    display: flex;
    justify-content: space-around;
}

.card-banner {
    width: 100%;
    margin: 0px;
    padding: 0px;
    border-radius: 28px;
    position: relative;
}

.feedbackprioritycontent {
    padding: 10px;
    font-size: 14px;
}

.pagination-container {
    display: flex !important;
    justify-content: end !important;
    height: 30px !important;
    width: 100%;
}

.paginationBtn {
    position: relative !important;
    min-width: 50px !important;
    border-radius: 10px !important;
    margin: 0px 2px !important;
    border: 0.5px solid !important;
}

.saveAllChanges-btn {
    background-color: #787777 !important;
    text-transform: capitalize !important;
    color: white !important;
}

.modal {
    display: none;
    position: fixed;
    z-index: 1;
    padding-top: 100px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
}

.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

.showPriorityClass {
    display: block;
}

.hidePriorityClass {
    display: none;
}

.view-download-btn {
    border: 1px solid black !important;
    color: black !important;
    text-transform: capitalize !important;
}

.error-message {
    font-size: 12px;
    color: red;
    padding: 4px 0px 0px;
}

.feedback-page-GoToEssay {
    text-decoration: underline !important;
    color: #0a66f0f2 !important;
    cursor: pointer;
}

.cancel-icon-font {
    font-size: 20px !important;
    color: gray;
    cursor: pointer;
}

.cancel-icon-div {
    display: flex;
    justify-content: end;
    position: absolute;
    right: -8px;
    top: -8px;
    background-color: white;
}

.question-tabs .MuiTabs-scroller {
    overflow-x: scroll !important;
}

.question-tabs .MuiTabs-scroller::-webkit-scrollbar {
    height: 5px !important;
}

.question-tabs .MuiTabs-scroller::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.question-tabs .MuiTabs-scroller::-webkit-scrollbar-thumb {
    background: #787777;
    border-radius: 30px !important;
}

.question-tabs .MuiTabs-scroller::-webkit-scrollbar-thumb:hover {
    background: #787777;
}

.empty-quesion-div {
    margin: 0% 0% 0% 10%;
}

.cards-empty-div {
    margin: 30% 0% 0% 160%;
}

.pagination-container-div-warp {
    padding: 10px !important;
    width: 60%;
}

.add-card-btn-div {
    width: 100%;
    display: flex;
    justify-content: end;
    margin: 30px 0px 0px 0px;
}

.add-card-modal-box {
    width: 50%;
    padding: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 10px;
    box-shadow: 24;
}

.add-card-filed-gap {
    margin: 0px 0px 20px 0px;
}

.add-card-modal-btn-div {
    display: flex;
    justify-content: center;
}

.add-card-save-div {
    margin: 0px 0px 0px 10px;
}

.add-card-cancel-div {
    margin: 0px 10px 0px 0px;
}