.custom-box-style {
    position: relative;
    width: 60%;
    padding: 20px;
    box-shadow: -7px 7px 36px -3px rgba(0, 0, 0, 0.7);
    border-radius: 1%;
}

.grade-field-warps {
    margin: 20px 0px;
}


.grade-parent-div {
    display: flex;
    align-items: center;
    margin: 20px 0px;
}

.grade-newEssay-lable {
    font-size: 18px;
    font-weight: 600;
    font-style: normal;
    line-height: 21.6px;
    color: #070519;
    display: flex;
    justify-content: center;
    margin: 0px;
    width: 100%;
}

.grade-next-button-warp {
    display: flex;
    justify-content: center;
    margin: 0px;
}

.tabs {
    overflow: hidden !important;
    display: flex !important;
    justify-content: center !important;
    border-bottom: 1px solid black !important;
    padding-bottom: 8px !important;
}

.tabs button {
    position: relative !important;
    width: 25% !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    margin: 0px 10px !important;
    border-radius: 10px !important;
}

.tab {
    background-color: #f1f1f1 !important;
    float: left !important;
    cursor: pointer !important;
    padding: 10px 15px !important;
    text-transform: capitalize !important;
    color: black !important;
    border-radius: 5px 5px 0 0 !important;
}

.tab:hover {
    background-color: #ddd !important;
}

.activeTab {
    background-color: #ff00002e !important;
}

.tab-content {
    padding: 20px 0px 0px;
    border-radius: 0 5px 5px 5px;
}

.grade-textArea-first-warps {
    width: 100%;
    margin: 0px 0px 40px 0px;
}

.grade-textArea-warps {
    width: 100%;
    margin: 0px 0px 26px 0px;
}

.parent-div {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1% 0% 5%;
}

.hr-tag-line {
    width: 100%;
    border: 1px solid #ccc;
    margin: 2% 0%;
}

.action-btn {
    display: flex;
}

.logo-back-parent-div {
    margin: 1% 1% 0%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.table-warps {
    box-shadow: none !important;
    border-radius: 0px !important;
}

.saveTemplateNameWrapper {
    display: flex;
    width: 90%;
    flex-direction: column;
    margin: 20px 0px 0px;
}

.namesubsectionone {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.save-template-btn {
    display: flex;
    width: 66%;
    justify-content: center;
    text-transform: capitalize;
    margin-top: 10px;
}

.get-preview-btn {
    display: flex;
    width: 56%;
    justify-content: start;
    align-items: center;
}

.errer-message {
    font-size: 12px;
    color: red;
    padding: 4px 0px 0px;
}

.errer-message-handwriting {
    font-size: 12px;
    justify-content: center;
    display: flex;
    color: red;
    margin: 0px 0px 15px;
}

.is-handwriting-formcontrol {
    display: flex !important;
    flex-direction: row !important;
    justify-content: center !important;
    align-items: center !important;
}

.is-handwriting-formOne {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
}

.is-handwriting-div {
    width: 100% !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.loader-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffffb5;
    z-index: 3;
}


progress {
    width: 500px;
    height: 24px;
    transition: width 1s ease-in-out;
}

#progressBarWrapper {
    position: absolute;
    text-align: center;
    width: 96%;
}

#progressText {
    position: relative;
    left: 8px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

.question-wrapper-div {
    display: flex;
}

.image-container {
    display: flex;
    gap: 10px;
}

.thumbnail {
    width: 70px;
    height: auto;
    cursor: move;
    margin-top: 10px;
    margin-bottom: 10px;
}

.textbox-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.textbox-container input[type="text"] {
    width: 100%;
    padding: 5px;
}

#responseContainer {
    width: 98%;
    position: relative;
    padding: 10px;
    margin: 0px 12px;
    border-radius: 12px;
    margin-bottom: 16px;
    border-color: #dbcaca;
    border-width: 1px;
    border-style: solid;
    margin-top: 16px;
}

.row {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
    padding: 10px 0;
}

.column {
    padding: 10px;
}

.headings-row {
    font-weight: bold;
    margin-bottom: 10px;
    width: 100%;
    border-bottom: 1px solid #ccc;
    position: relative;
    display: flex;
    margin: 0px;
}

.headings-row div {
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
}

.column:last-child {
    border-right: none;
}

.question-column {
    width: 22%;
    display: flex;
    flex-direction: column;
}

.question-column h4 {
    font-family: "Poppins", sans-serif;
}

.image-column-header,
.image-column {
    width: 34%;
    display: flex;
    flex-wrap: wrap;
    border-right: 3px solid #ccc;
    border-left: 3px solid #ccc;
}

.textbox-column-header,
.textbox-column {
    width: 22%;
    display: flex;
}

.ModelSolution-textbox-column-header,
.ModelSolution-textbox-column {
    width: 22%;
    display: flex;
    border-right: 3px solid #ccc;
}

.customtextareaScroll::-webkit-scrollbar {
    width: 6px !important;
    background-color: #F5F5F5 !important;
}

.customtextareaScroll::-webkit-scrollbar-thumb {
    background-color: #000000 !important;
    border: 2px solid #555555 !important;
    border-radius: 24px !important;
}

.row-options {
    display: flex !important;
    justify-content: end !important;
}

.custombtnspacing {
    margin-left: 4px;
    margin-right: 4px;
}

.row-seperator {
    position: relative !important;
    width: 100% !important;
    border: 1px solid #ccc !important;
    margin: 8px 0px !important;
}

.row.data-row {
    width: 100%;
    margin: 0px;
}

.row.data-row:not(:last-child) {
    border-bottom: 1px solid #ccc;
}

.maxMarksValueElem:hover>*[contenteditable="true"] {
    background-color: rgba(217, 245, 255, 0.5);
}

.maxWordsValueElem,
.maxMarksValueElem {
    position: relative;
    left: 10px;
    padding: 4px;
    width: 40px;
}

.sampleSpanWrapper {
    position: relative;
    display: flex;
    padding: 0px 0px 4px 0px;
    align-items: center;
}

.questionMetaDataDiv {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: left;
    font-size: 14px;
    padding: 6px 0px 0px;
}

.close-icon {
    position: relative;
    cursor: pointer;
    visibility: hidden;
    width: 20px;
    height: 20px;
    padding: 0px 10px;
}

.customColumnBorder {
    border-right: 0px !important;
    display: flex;
    flex-direction: column;
}

.modelSolutionCustomColumnBorder {
    display: flex;
    flex-direction: column;
}

.addrowaction {
    position: relative;
    padding: 10px;
    display: flex;
    justify-content: end;
}

.add-row {
    background-color: #089d08;
    color: white;
    font-size: 12px;
}

.delete-row {
    background-color: #ff3c3cc7;
    color: white;
    font-size: 12px;
}

.addrowaction button {
    margin: 0px 8px;
    width: 28px;
    height: 24px;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    border: none;
}

.instCountClass {
    position: absolute;
    top: 24px;
    left: 20px;
}

.question-wrapper-div {
    display: flex;
}

.customAlign {
    position: relative;
    padding: 10px;
    font-weight: 400;
    font-size: 16px;
    color: #777777;
    font-family: "Poppins", sans-serif;
}

.edit-questions-h5 {
    font-size: 18px;
    font-weight: 600;
    font-style: normal;
    line-height: 21.6px;
    color: #070519;
    display: flex;
    margin: 0px;
    width: 100%;
}

.applyTemplateSelectWrapper {
    position: relative;
    width: 30%;
}

.edit-questions-partent-div-warps {
    display: flex;
    margin: 0px 22px 16px;
    width: 100%;
    height: 50px;
    align-items: center;
}

.edit-questions-div-warps {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
}

.finalize-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7px;
    background: #555555 !important;
    color: white !important;
    text-transform: capitalize !important;
}

.finalize-btn:hover {
    background: #4a4949 !important;
    color: white !important;
    text-transform: capitalize !important;
}

.select-template-to-apply-dd {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: end;
}

.modal-paper {
    display: none;
    position: fixed;
    z-index: 1;
    padding-top: 100px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
}

.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

.disagree-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #555555 !important;
    color: white !important;
    text-transform: capitalize !important;
}

.apply-template-btn {
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 20px 0px;
}

.view-templateName {
    font-size: 18px;
    font-weight: 600;
    font-style: normal;
    line-height: 21.6px;
    color: #070519;
    display: flex;
    margin: 10px 0px 10px 0px;
    width: 100%;
}

.agree-disagree-btn-div {
    display: flex !important;
    justify-content: center !important;
}

.no-existing-template {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.logout-go-to-essay-partent-div {
    display: flex;
    align-items: center;
}

.grade-page-loader {
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    background: #ffffffb5;
    position: fixed;
    top: 0;
    bottom: 0;
}

.grade-page-logout-btn {
    font-family: "Poppins", sans-serif !important;
    font-size: 16px !important;
    text-transform: capitalize !important;
    color: #0a66f0f2 !important;
    border: 1px solid #0a66f0f2 !important;
}

.progress-bar-styles {
    height: 8px !important;
    border-radius: 5px !important;
}

.linear-progress-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #ffffff82;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loader-linear-wrapper {
    width: 75%;
    z-index: 1000;
}

.loader-linear-wrapper .MuiLinearProgress-root {
    height: 8px !important;
    border-radius: 5px !important;
}

.template-table-head-cell1 {
    background: #555555 !important;
    color: white !important;
    width: 24%;
}

.template-table-head-cell2 {
    background: #555555 !important;
    color: white !important;
    width: 13%;
}

.template-table-head-cell3 {
    background: #555555 !important;
    color: white !important;
    width: 25%;
}

.new-template-table-head-cell1 {
    width: 22%;
}

.new-template-table-head-cell2 {
    width: 12%;
}

.new-template-table-head-cell3 {
    width: 10%;
}

.new-rowadded-bg {
    background-color: #14040424;
}

.show-content-total-que-warp {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 8px 14px 0px;
    height: 60px;
}

.remove-margin-h {
    font-size: 16px;
    font-weight: 600;
    font-style: normal;
    line-height: 21.6px;
    color: #070519;
    display: flex;
    margin: 0px;
    width: 100%;
    justify-content: space-between;
}

.ques-count-warp {
    margin: 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 50px;
    width: 18%;
}